<!--
  - Copyright (C) 2022. Archimedes Exhibitions GmbH,
  - Saarbrücker Str. 24, Berlin, Germany
  -
  - This file contains proprietary source code and confidential
  - information. Its contents may not be disclosed or distributed to
  - third parties unless prior specific permission by Archimedes
  - Exhibitions GmbH, Berlin, Germany is obtained in writing. This applies
  - to copies made in any form and using any medium. It applies to
  - partial as well as complete copies.
  -->

<template>
  <b-container class="py-4" fluid>
    <div class="search-wrapper mb-5 mt-4">
      <input
        type="text"
        ref="search"
        v-model="searchQuery"
        :placeholder="$t('ems.common.search')"
      />
    </div>
    <table class="table table-hover">
      <thead class="thead-light">
        <tr>
          <th
            v-for="(value, key) in tableFields"
            :key="key"
            :class="value.key === 'actions' ? 'text-right' : ''"
          >
            {{ value.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(value, key) in resultQuery" :key="key">
          <th scope="row">
            {{ value.client }}
          </th>
          <td>
            {{ value.content_version }}
          </td>
          <td>
            <span v-if="value.syncJob === 'n/a'">{{ value.syncJob }}</span>
            <b v-if="value.syncJob === 'QUEUED'" class="text-warning">{{
              value.syncJob
            }}</b>
            <b v-if="value.syncJob === 'SYNCING'" class="text-primary">{{
              value.syncJob
            }}</b>
            <b v-if="value.syncJob === 'COMPLETED'" class="text-success">{{
              value.syncJob
            }}</b>
            <b v-if="value.syncJob === 'ERROR'" class="text-danger">{{
              value.syncJob
            }}</b>
          </td>
          <td>
            <span v-if="value.downloadJob === 'n/a'">{{
              value.downloadJob
            }}</span>
            <b v-if="value.downloadJob === 'QUEUED'" class="text-warning">{{
              value.downloadJob
            }}</b>
            <span v-if="value.downloadJob === 'DOWNLOADING'">Preparing...</span>
            <b
              v-if="value.downloadJob === 'COMPLETED' && !value.assetsPath"
              class="text-success"
              >{{ value.downloadJob }}</b
            >
            <span v-if="value.assetsPath">Please wait...</span>
            <b v-if="value.downloadJob === 'ERROR'" class="text-danger">{{
              value.downloadJob
            }}</b>
          </td>
          <td class="text-right">
            <b-button
              variant="primary"
              class="mx-2"
              size="sm"
              v-on:click="invokeSync(value.client)"
              :disabled="
                value.syncJob === 'QUEUED' ||
                value.syncJob === 'SYNCING' ||
                !userHasAccess([scopeMethods.WRITE], false)
              "
            >
              <b-spinner
                small
                label="Loading..."
                v-if="value.syncJob === 'QUEUED' || value.syncJob === 'SYNCING'"
              ></b-spinner>
              <b-icon-arrow-clockwise v-else></b-icon-arrow-clockwise>
              Sync
            </b-button>
            <b-button
              variant="dark"
              class="mx-2"
              size="sm"
              v-if="value.syncJob === 'COMPLETED'"
              v-on:click="invokeDownload(value.client)"
              :disabled="
                value.downloadJob === 'DOWNLOADING' ||
                value.assetsPath ||
                !userHasAccess([scopeMethods.WRITE], false)
              "
            >
              <template v-if="value.downloadJob === 'DOWNLOADING'">
                <b-spinner small label="Loading..."></b-spinner>
                Preparing
              </template>
              <template v-else-if="value.assetsPath">
                <b-spinner small label="Loading..."></b-spinner>
                Wait...
              </template>
              <template v-else>
                <b-icon-cloud-download-fill></b-icon-cloud-download-fill>
                Download
              </template>
            </b-button>
          </td>
        </tr>
      </tbody>
    </table>
    <b-alert class="text-center" show v-if="!tableData.length">
      <b-icon-exclamation-diamond></b-icon-exclamation-diamond>
      {{ $t('ems.common.nothingFound') }}
    </b-alert>
  </b-container>
</template>

<script>
  import Vue from 'vue'
  import saveAs from 'file-saver'

  export default {
    name: 'CPacker',
    data () {
      return {
        clients: [],
        syncJobs: [],
        downloadJobs: [],
        searchQuery: null,
        perPage: 20,
        currentPage: 1,
        updateTimer: null,
        tableData: [],
        tableFields: [
          {
            key: 'client',
            label: 'Exhibit',
            sortable: true
          },
          {
            key: 'content_version',
            label: 'Content Version',
            sortable: true
          },
          {
            key: 'sync_status',
            label: 'Sync status'
          },
          {
            key: 'download_status',
            label: 'Download status'
          },
          {
            key: 'actions',
            label: 'Actions'
          }
        ]
      }
    },
    computed: {
      resultQuery () {
        if (this.searchQuery) {
          return this.tableData.filter((item) => {
            return this.searchQuery
              .toLowerCase()
              .split(' ')
              .every(
                (v) =>
                  item.client.toLowerCase().includes(v) ||
                  item.content_version.toLowerCase().includes(v)
              )
          })
        } else {
          return this.tableData
        }
      },
      scopeMethods () {
        return Vue.prototype.$keycloakmanager.scopeMethods
      }
    },
    watch: {
      clients () {
        this.updateTableData()
      },
      syncJobs () {
        this.updateTableData()
      },
      downloadJobs () {
        this.updateTableData()
      }
    },
    methods: {
      saveFile (job) {
        // https://github.com/eligrey/FileSaver.js
        // console.log(job)
        fetch(
          window.location.origin +
            '/cpacker/assets/' +
            job.assets_path.substring(8),
          {
            method: 'GET',
            headers: this.$keycloakmanager.getTokenHeader()
          }
        )
          .then((response) => response.blob())
          .then((blob) => {
            saveAs(blob, job.assets_path.substring(8))
            let client = this.getClient(job.client_id)
            client.assetsPath = null
            this.updateTableData()
            this.makeToast('OK! Download finished for ' + job.client_id)
          })
      },
      updateTableData () {
        this.tableData = [] // reset
        this.clients.map((c) => {
          if (!this.tableData.includes(c)) {
            this.tableData.push({
              client: c.client_id,
              content_id: c.content_id === '' ? 'n/a' : c.content_id,
              content_version:
                c.content_version === '' ? 'n/a' : c.content_version,
              syncJob: c.syncJob ? c.syncJob.job_status : 'n/a',
              downloadJob: c.downloadJob ? c.downloadJob.job_status : 'n/a',
              assetsPath: c.assetsPath,
              actions: {
                client_id: c.client_id,
                content_id: c.content_id
              }
            })
          }
        })
      },

      getClient (clientId) {
        return this.clients.find((c) => c.client_id === clientId)
      },

      getSyncJob (jobId) {
        return this.syncJobs.find((j) => j.job_id === jobId)
      },

      getDownloadJob (jobId) {
        return this.downloadJobs.find((j) => j.job_id === jobId)
      },

      addSyncJob (job, clientId) {
        this.syncJobs.push(job)
        let client = this.getClient(clientId)
        client.syncJob = job
        this.updateSyncJob(job)
      },

      addDownloadJob (job, clientId) {
        this.downloadJobs.push(job)
        let client = this.getClient(clientId)
        client.downloadJob = job
        this.updateDownloadJob(job)
      },

      getSyncJobs () {
        this.syncJobs.map((j) => {
          this.fetchSyncJob(j.job_id).then((res) => {
            this.updateSyncJob(res)
          })
        })
      },

      getDownloadJobs () {
        this.downloadJobs.map((j) => {
          this.fetchDownloadJob(j.job_id).then((res) => {
            this.updateDownloadJob(res)
          })
        })
      },

      updateSyncJob (job) {
        let _job = this.getSyncJob(job.job_id)
        _job.job_status = job.job_status

        if (job.job_status === 'COMPLETED') {
          let jobIdx = this.syncJobs.findIndex((j) => {
            return j.job_id === job.job_id
          })
          this.syncJobs.splice(jobIdx, 1)
          this.makeToast('Ok, content is ready to download!')
        }
        this.updateTableData()
      },

      updateDownloadJob (job) {
        let _job = this.getDownloadJob(job.job_id)
        _job.job_status = job.job_status

        if (job.job_status === 'COMPLETED') {
          // update job status
          let _job = this.getDownloadJob(job.job_id)
          _job.job_status = job.job_status
          // update assetPath
          let client = this.getClient(job.client_id)
          client.assetsPath = job.assets_path
          // remove job from list to stop polling job status
          let jobIdx = this.downloadJobs.findIndex((j) => {
            return j.job_id === job.job_id
          })
          this.downloadJobs.splice(jobIdx, 1)
          // invoke save
          this.saveFile(job)
          this.makeToast('OK! Starting download for ' + job.client_id)
        }
        this.updateTableData()
      },

      makeToast (msg, variant) {
        this.$bvToast.toast([msg], {
          title: 'CPacker response',
          solid: true,
          autoHideDelay: 3500,
          toaster: 'b-toaster-bottom-right',
          variant: variant
        })
      },

      invokeSync (clientId) {
        this.onSync(clientId).then((res) => {
          if ('detail' in res) {
            this.makeToast(res.detail)
          } else {
            this.makeToast('Start syncing content for ' + clientId)
            this.addSyncJob(res, clientId)
          }
        })
      },

      onSync (clientId) {
        console.log('invoke sync for clientID: ' + clientId)
        return new Promise((resolve) => {
          fetch('/cpacker/api/v1/clients/' + clientId + ':sync', {
            method: 'POST',
            headers: this.$keycloakmanager.getTokenHeader()
          })
            .then((resp) => resp.json())
            .then((response) => {
              resolve(response)
            })
        })
      },

      invokeDownload (clientId) {
        this.onDownload(clientId).then((res) => {
          this.addDownloadJob(res, clientId)
          this.makeToast('Preparing data for ' + clientId)
        })
      },

      onDownload (clientId) {
        console.log('invoke download for client: ' + clientId)
        return new Promise((resolve) => {
          fetch('/cpacker/api/v1/assets/' + clientId + ':download', {
            method: 'POST',
            headers: this.$keycloakmanager.getTokenHeader()
          })
            .then((resp) => resp.json())
            .then((response) => {
              resolve(response)
            })
        })
      },

      fetchClients () {
        return new Promise((resolve, reject) => {
          fetch('/cpacker/api/v1/clients', {
            headers: this.$keycloakmanager.getTokenHeader()
          })
            .then((resp) => resp.json())
            .then((response) => {
              resolve(response)
            })
            .catch((error) => {
              console.error('Error:', error)
              this.makeToast(error + ' in fetchClients', 'danger')
              reject(error)
            })
        })
      },

      fetchSyncJob (jobId) {
        return new Promise((resolve) => {
          fetch('/cpacker/api/v1/clients/jobs/' + jobId, {
            headers: this.$keycloakmanager.getTokenHeader()
          })
            .then((resp) => resp.json())
            .then((response) => {
              resolve(response)
            })
        })
      },

      fetchDownloadJob (jobId) {
        return new Promise((resolve) => {
          fetch('/cpacker/api/v1/assets/downloads/' + jobId, {
            headers: this.$keycloakmanager.getTokenHeader()
          })
            .then((resp) => resp.json())
            .then((response) => {
              resolve(response)
            })
        })
      },

      userHasAccess (methods, isPermissive = true) {
        return Vue.prototype.$keycloakmanager.userHasAccess(
          Vue.prototype.$keycloakmanager.scopes.PACKAGES,
          methods,
          isPermissive
        )
      }
    },
    mounted () {
      this.fetchClients().then((res) => {
        this.clients = res
        this.clients.map((c) => {
          // add jobs property
          c.syncJob = null
          c.downloadJob = null
          c.assetsPath = null
        })
      })
      this.updateTimer = setInterval((_) => {
        this.getSyncJobs()
        this.getDownloadJobs()
      }, 1000)
      this.updateTableData()
    },
    beforeDestroy () {
      clearInterval(this.updateTimer)
    }
  }
</script>

<style scoped></style>
